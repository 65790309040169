<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          :fields="[
            //
            'id',
            //'property_id',
            getHelperObject('property', 'property_id', 'properties'),
            'email',
            { ...this.helpers.validIcons, key: 'is_ignored', label: 'Ignored' },
            { ...this.helpers.validIcons, key: '_status' }
          ]"
          :config="{ url: 'admin/gmail/emails', route: '/super/gmail/emails', name: 'email', _active: false }"
          :columnFilter="true"
          @preparedHelpers="onPreparedHelpers"
        />
      </transition>
    </CCol>
  </CRow>
</template>


<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'GMailEmails',
  mixins: [crudMixin]
}
</script>
