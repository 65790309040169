var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('ACRUDTable',{attrs:{"fields":[
          //
          'id',
          //'property_id',
          _vm.getHelperObject('property', 'property_id', 'properties'),
          'email',
          Object.assign({}, this.helpers.validIcons, {key: 'is_ignored', label: 'Ignored'}),
          Object.assign({}, this.helpers.validIcons, {key: '_status'})
        ],"config":{ url: 'admin/gmail/emails', route: '/super/gmail/emails', name: 'email', _active: false },"columnFilter":true},on:{"preparedHelpers":_vm.onPreparedHelpers}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }